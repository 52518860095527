import React from "react";

import NavMenu from "../../components/NavMenu";
import VideoSection from "../../components/VideoSection";
import BirthDayForm from "../../components/BirthdayForm";
import AboutSection from "../../components/AboutSection";
import WhatMovesUs from "../../components/WhatMovesUs";
import OpenInnovation from "../../components/OpenInnovation";
import BePartOfIt from "../../components/BePartOfIt";
import Footer from "../../components/Footer";
import OurAreas from "../../components/OurAreas";
import Ecosystem from "../../components/Ecosystem";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import NavTagCyber from "../../components/NavTagCyber";
import CyberSecurity from "../../components/CyberSecuritySection";

function Home() {
  React.useEffect(() => {
    // Incluir versao do Package.json no console
    console.log(
      `%c Sharingan: ${require("../../../package.json").version}`,
      "color: black; background-color: white; padding: 5px 10px;"
    );

    // Inicializando o Google Analytics
    ReactGA.initialize("G-FFD35VQ3HV");
    ReactGA.send("pageview");
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Alma Sírio-Libânes: Inovação e Tecnologia na área da saúde.
        </title>
        <meta name="robots" content="index" />
      </Helmet>
      <NavTagCyber />
      <NavMenu />
      <VideoSection />
      {/* <BirthDayForm /> */}
      <AboutSection />
      <WhatMovesUs />
      <OurAreas />
      <OpenInnovation />
      <Ecosystem />
      <CyberSecurity />
      <BePartOfIt />
      <Footer />
    </>
  );
}

export default Home;
