import styled from "styled-components";
import { device } from "./../../components/device";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0e152d;
  margin-top: 350px;
  @media ${device.mobile} {
    margin-top: 0px;
    display: block;
  }
`;

export const Content = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: auto;
  @media ${device.mobile} {
    margin-top: 50px;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const TitleEvent = styled.div`
  width: 100%;
  margin: 50px 0;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: -3.42px;

  svg {
    width: 15.67px;
    height: 20px;
    path {
      fill: #ffff;
    }
  }
`;

export const TextList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  gap: 20.42px;
  svg {
    width: 35.67px;
    height: 25px;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: -8px;
  color: #ffffff;

  @media (max-width: 767px) {
    width: 78%;
    font-size: 2.1rem;
    margin-top: -6px;
  }
`;
export const TitleBlue = styled.div`
  color: #96d7fc;
`;

export const Text = styled.h1`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #96d7fc;
`;

export const Link = styled.div`
  max-width: 21.125rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 138px;
  height: 48px;
  background: #78c3ed;
  border-radius: 500px;

  img {
    margin-left: 0.583rem;
    margin-right: 1.582rem;
  }
  @media ${device.mobile} {
    width: 100%;
    position: absolute;
    top: 93%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const CircleImage = styled.img`
  width: 120%;
  @media ${device.mobile} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: auto;

  a {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #0f162e;
    text-decoration: none;
  }
`;

export const ImageContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  img {
    height: 125px;
    width: 101px;
    margin-top: -125px;
    margin-left: 70px;
  }

  a {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #0f162e;
    text-decoration: none;
  }

  /* @media ${device.desktop} {
    img {
      height: 125px;
      width: 101px;
      left: 509px;
      margin-top: -125px;
      border-radius: 0px;
      margin-left: 330px;
    }
  }

  @media ${device.tablet} {
    img {
      height: 125px;
      width: 101px;
      left: 509px;
      margin-top: -125px;
      border-radius: 0px;
      margin-left: 330px;
    }
  }

  @media ${device.mobile} {
    img {
      height: 80px;
      width: 65px;
      display: flex;
      justify-content: left;
      justify-content: center;
      margin-top: -530px;
      margin-left: -4px;
    }
  } */
`;

export const ContentSponsors = styled.div`
  margin: 15px;
  padding: 10px;
  background-color: #ebebeb;
  border-radius: 10px;

  h3 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e152d;
  }

  img {
    width: 45px;
    margin: 5px;
  }

  @media ${device.mobile} {
    margin: 0 20px;
  }
`;

export const Cota = styled.div`
  margin-right: 30px;

  p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e152d;
  }
`;
