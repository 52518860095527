import React, { useState } from "react";

import {
  Container,
  LogoContent,
  LogoContentMObile,
  Logo,
  HeaderText,
  TextContainer,
  TextContent,
  TextCyberContent,
  TextCyberContentMobile,
  SubTextCyber,
  SubTextCyberMobile,
  ButtonBorder,
  ButtonIcon,
  Button,
  ButtonContainer,
  ButtonClose,
} from "./styles";

import Cyber from "../../assets/images/cyber-image.png";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";

const NavTagCyber = (isMargin) => {
  const [closeWindow, setCloseWindow] = useState(false);

  const handleScroll = (id) => {
    if (window !== undefined) {
      window.scrollTo({
        top: document.getElementById(id).offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  return closeWindow ? (
    <></>
  ) : (
    <Container>
      <LogoContent>
        <Logo src={Cyber} alt="Alma Sírio-Libânes" />
      </LogoContent>
      <LogoContent>
        <TextContainer>
          <HeaderText>
            <p>ALMA CYBER SECURITY SUMMIT</p>
          </HeaderText>
          <TextCyberContent>
            Fórum de
            <TextContent>Cyber Security</TextContent>
            <SubTextCyber>para o Setor da Saúde</SubTextCyber>
          </TextCyberContent>
        </TextContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              handleScroll("cyber");
            }}
          >
            Saiba mais
          </Button>
        </ButtonContainer>
        <ButtonClose>
          <CloseIcon onClick={() => setCloseWindow(true)} />
        </ButtonClose>
      </LogoContent>
      <LogoContentMObile isMargin={isMargin}>
        <TextContainer>
          <HeaderText>ALMA CYBER SECURITY SUMMIT</HeaderText>
          <TextCyberContentMobile>
            Fórum de
            <TextContent>Cyber Security</TextContent>
            <SubTextCyber>para o Setor da Saúde</SubTextCyber>
          </TextCyberContentMobile>
          <SubTextCyberMobile>para o Setor da Saúde</SubTextCyberMobile>
          <ButtonContainer>
            <Button
              onClick={() => {
                handleScroll("cyber");
              }}
            >
              Saiba mais
            </Button>
            <ArrowDown />
          </ButtonContainer>
        </TextContainer>
        <ButtonClose>
          <CloseIcon onClick={() => setCloseWindow(true)} />
        </ButtonClose>
        <Logo src={Cyber} alt="Alma Sírio-Libânes" />
      </LogoContentMObile>
    </Container>
  );
};

export default NavTagCyber;
