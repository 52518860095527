import styled from "styled-components";
import { device } from "./../../components/device";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  background-color: #0f162e;

  @media (max-width: 648px) {
    width: 100%;
    height: 190px;
    margin-top: -5px;
  }
`;

export const Logo = styled.img`
  height: 120px;
  width: 118px;
  margin-left: 88px;
  top: 0px;
  border-radius: 0px;
  @media ${device.laptop} {
    margin-left: 50px;
  }
  @media (max-width: 648px) {
    height: 179px;
    width: 100%;
    margin-top: -80px;
    opacity: 60%;
  }
`;

export const LogoContent = styled.div`
  display: flex;
  justify-content: start;
  @media ${device.tablet} {
    display: none;
  }

  @media (max-width: 648px) {
    display: none;
  }
`;
export const LogoContentMObile = styled.div`
  display: none;

  @media ${device.tablet} {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: end;
  }
  @media ${device.mobile} {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: end;
    margin-top: 80px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: center;
  align-items: start;
  justify-content: start;

  @media ${device.desktopLarge} {
    width: 100%;
    margin-left: 250px;
    display: flex;
    width: 700px;
  }
  @media ${device.desktop} {
    display: flex;
    width: 670px;
  }

  @media ${device.laptop} {
    display: flex;
    width: 390px;
  }

  @media ${device.tablet} {
    width: 1000px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 80px;
  }
  @media (max-width: 648px) {
    width: 150%;
    display: flex;
    justify-content: start;
    margin-top: -5px;
  }
`;
export const HeaderText = styled.p`
  width: 100%;
  display: flex;
  font-family: "Inter";
  justify-content: start;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2em;
  color: #96d7fc;

  @media ${device.laptop} {
    font-size: 11px;
    margin-bottom: 17px;
  }

  @media ${device.tablet} {
    display: flex;
    margin-left: 24px;
  }
  @media (max-width: 648px) {
    height: 14px;
    width: 120%;
    left: 24px;
    top: 30px;
    margin-bottom: 30px;
    margin-right: -185px;
    margin-top: ${(props) => (props.isMargin ? "0px" : "-80px")};
    display: flex;
    justify-content: start;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const TextCyberContent = styled.div`
  display: flex;
  justify-content: start;
  display: flex;
  align-items: center;
  font-family: "Inter";
  flex-direction: row;
  width: 100%;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
  margin-top: -60px;

  @media ${device.laptop} {
    width: 140%;
    margin-top: -50px;
    font-size: 19px;
  }
`;

export const TextCyberContentMobile = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-self: center;
    flex-direction: row;
    margin-top: -35px;
    margin-left: -90px;
    gap: 10px;
    font-weight: 700;
    font-size: 20px;
  }

  @media (max-width: 648px) {
    width: 120%;
    height: 60px;
    margin-left: 104px;
    margin-bottom: 8px;
    gap: 5px;
    font-size: 25px;
    text-align: justify;
    flex-direction: row;
    justify-content: start;
    display: flex;
    font-weight: 700;
  }
`;

export const SubTextCyber = styled.div`
  @media ${device.tablet} {
    font-size: 20px;
    font-weight: 700;
  }
  @media (max-width: 648px) {
    display: none;
  }
`;
export const SubTextCyberMobile = styled.div`
  @media ${device.tablet} {
    height: 0px;
    color: #0f162e;
  }
  @media (max-width: 648px) {
    width: 100%;
    font-size: 25px;
    margin-left: 24px;
    margin-top: -20px;
    font-weight: 700;
    color: #fff;
  }
`;

export const TextContent = styled.p`
  color: #96d7fc;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  align-self: center;

  margin-left: 69px;
  color: #0f162e;

  @media ${device.desktopLarge} {
    margin-right: 10px;
    margin-left: 20px;
  }
  @media ${device.laptop} {
    margin-right: -30px;
    margin-left: 100px;
    font-size: 12px;
  }

  @media ${device.tablet} {
    width: 100%;
    display: flex;
    margin-left: -28px;
    margin-top: -24px;
    justify-content: start;
    align-items: start;
    align-self: flex-start;
    margin-bottom: 150px;
    gap: -10px;
    svg {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      path {
        fill: #ffff;
      }
    }
  }

  @media (max-width: 648px) {
    display: flex;
    z-index: 1;
    width: 80%;
    color: #ffff;
    margin-top: 16px;
    margin-left: -20px;
    margin-bottom: 20px;
    cursor: pointer;
    svg {
      display: flex;
      z-index: 1;
      margin-top: 30px;
      /* margin-right: -30px; */
      margin-left: 95px;
    }
  }
`;
export const Button = styled.div`
  height: 48px;
  width: 131px;
  left: 0px;
  top: 0px;
  border-radius: 500px;
  padding: 0px 24px 0px 24px;

  left: 0px;
  top: 0px;
  border-radius: 500px;
  cursor: pointer;
  display: flex;
  background: #78c3ed;
  border-radius: 500px;
  align-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    display: flex;
    width: 80px;
    height: 40px;
    margin-right: -120px;
  }
  @media ${device.tabletLarge} {
    width: 90px;
    height: 40px;
    margin-left: 16px;
  }
  @media ${device.tablet} {
    display: flex;
    z-index: 1;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    background: #0f162e;
    text-decoration: underline;
  }

  @media ${device.mobile} {
    display: flex;
    z-index: 1;
    width: 100px;
    height: 28px;
    display: flex;
    background: #0f162e;
    border-radius: 0px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 26px;
    text-decoration: underline;
  }
`;

export const ButtonIcon = styled.div`
  svg {
    path {
      fill: #0f162e;
    }
  }
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    svg {
      path {
        fill: #ffff;
      }
    }
  }

  @media (max-width: 648px) {
    margin-left: 40px;
    margin-top: -60px;
    svg {
      path {
        fill: #ffff;
      }
    }
  }
`;

export const ButtonClose = styled.div`
  display: flex;
  height: 25px;
  margin: 30px;
  justify-content: end;
  align-content: end;
  align-items: end;
  cursor: pointer;
  svg {
    path {
      fill: #ffff;
    }
  }

  @media ${device.desktop} {
    margin-left: 80px;
  }
  @media ${device.laptop} {
    margin-left: 180px;
  }

  @media ${device.tabletLarge} {
    margin-left: 180px;
  }
  @media ${device.tablet} {
    margin-left: -55px;
  }

  @media (max-width: 648px) {
    width: 100%;
    z-index: 1;
    margin-right: -197px;
    margin-bottom: 100px;
    margin-top: -45px;
  }
`;
