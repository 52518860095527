import React from "react";
import { captureOutboundLink } from "../../utils/captureOutboundLink";
import { useMediaQuery } from "@mui/material";

import {
  Wrapper,
  Container,
  Content,
  Title,
  TitleBlue,
  Text,
  Link,
  CircleImage,
  ContainerImage,
  TextContent,
  TextList,
  ImageContent,
  ImageContainer,
  ContentTitle,
  TitleEvent,
  ContentSponsors,
  Cota,
} from "./styles";

import AlmaSummit from "../../assets/images/alma-summit.png";
import { ReactComponent as Location } from "../../assets/icons/location-icon.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar-month-icon.svg";
import { ReactComponent as Day } from "../../assets/icons/duration-icon.svg";
import Cyber from "../../assets/images/cyber_24.png";
import CyberMobile from "../../assets/images/cyber_mobile_2024.png";
import AxurLogo from "../../assets/images/axur_logo.png";
import PartnerOneLogo from "../../assets/images/partnerone_logo.png";
import FortinetLogo from "../../assets/images/fortinet_logo.png";
import DanresaLogo from "../../assets/images/danresa_logo.png";
import DeloitteLogo from "../../assets/images/deloitte_logo.png";
import AkamaiLogo from "../../assets/images/akamai_logo.svg";
import SailPointLogo from "../../assets/images/sailpoint_logo.png";

import VitaraLogo from "../../assets/images/vitara_logo.png";
import OfHunterLogo from "../../assets/images/ofhunter_logo.png";
import AgemLogo from "../../assets/images/agem_logo.png";
import WithSecureLogo from "../../assets/images/withsecure_logo.png";
import LogicalItLogo from "../../assets/images/logicalit_logo.png";
import TrendMicroLogo from "../../assets/images/trendmicro_logo.png";
import ClarotyLogo from "../../assets/images/claroty_logo.png";
import NttLogo from "../../assets/images/ntt_logo.png";
import GigamonLogo from "../../assets/images/gigamon_logo.png";

import IblissLogo from "../../assets/images/ibliss_logo.png";
import ProofPointLogo from "../../assets/images/proofpoint_logo.png";
import MassimaFrogLogo from "../../assets/images/massimafrog_logo.png";
import Next4SecLogo from "../../assets/images/next4sec_logo.png";
import LumuLogo from "../../assets/images/lumu_logo.png";
import RSecGroupLogo from "../../assets/images/rsecgroup_logo.png";

const CyberSecurity = () => {
  const isMobile = useMediaQuery("(max-width:648px)");

  return (
    <Wrapper id="cyber">
      {isMobile ? (
        <>
          <ContainerImage>
            <CircleImage src={CyberMobile} alt="" />
            <a
              href="https://iep.hospitalsiriolibanes.org.br/eventos/ii-cyber-security"
              target="_blank"
              rel="noopener noreferrer"
              onClick={captureOutboundLink(
                "https://iep.hospitalsiriolibanes.org.br/eventos/ii-cyber-security"
              )}
            >
              <Link>Inscreva-se</Link>
            </a>
          </ContainerImage>
          <ContentSponsors>
            <h3>Patrocinadores</h3>
            <Cota>
              <p>Premium:</p>
              <img src={AxurLogo} />
              <img src={PartnerOneLogo} />
              <img src={FortinetLogo} />
              <img src={DanresaLogo} />
              <img src={DeloitteLogo} />
              <img src={AkamaiLogo} />
              <img src={SailPointLogo} />
            </Cota>
            <Cota>
              <p>Ouro:</p>
              <img src={VitaraLogo} />
              <img src={OfHunterLogo} />
              <img src={AgemLogo} />
              <img src={WithSecureLogo} />
              <img src={LogicalItLogo} />
              <img src={TrendMicroLogo} />
              <img src={ClarotyLogo} />
              <img src={NttLogo} />
              <img src={GigamonLogo} />
            </Cota>
            <Cota>
              <p>Prata:</p>
              <img src={IblissLogo} />
              <img src={ProofPointLogo} />
              <img src={MassimaFrogLogo} />
              <img src={Next4SecLogo} />
              <img src={LumuLogo} />
              <img src={RSecGroupLogo} />
            </Cota>
          </ContentSponsors>
        </>
      ) : (
        <>
          <ContainerImage>
            <CircleImage src={Cyber} alt="" />
          </ContainerImage>
          <Content>
            <TextContent>
              <ContentTitle>
                <Title>Save the Date</Title>
                <Title>
                  {" "}
                  <TitleBlue>
                    Alma Cyber <br />
                    Security 2024{" "}
                  </TitleBlue>
                </Title>
              </ContentTitle>
              <TitleEvent>
                <Title>Evento Presencial e Online</Title>
              </TitleEvent>
              <TextList>
                <Location />
                <Text>
                  Rua Prof. Daher Cutait, 69 <br /> Bela Vista São Paulo (SP)
                </Text>
              </TextList>
              <TextList>
                <Calendar />
                <Text>20 de junho de 2024</Text>
              </TextList>
              <TextList>
                <Day />
                <Text>8h30-18h30</Text>
              </TextList>
            </TextContent>

            <ImageContent>
              <a
                href="https://www.faculdadesiriolibanes.org.br/fsl/product/ii-f%C3%B3rum-de-cyber-security-para-o-setor-da-sa%C3%BAde/01tU4000002oYVCIA2"
                target="_blank"
                rel="noopener noreferrer"
                onClick={captureOutboundLink(
                  "https://www.faculdadesiriolibanes.org.br/fsl/product/ii-f%C3%B3rum-de-cyber-security-para-o-setor-da-sa%C3%BAde/01tU4000002oYVCIA2"
                )}
              >
                <Link>Inscreva-se</Link>
              </a>

              <img src={AlmaSummit} alt="Imagem Alma Summit" />
            </ImageContent>
            <ContentSponsors>
              <h3>Patrocinadores</h3>
              <Cota>
                <p>Premium:</p>
                <img src={AxurLogo} />
                <img src={PartnerOneLogo} />
                <img src={FortinetLogo} />
                <img src={DanresaLogo} />
                <img src={DeloitteLogo} />
                <img src={AkamaiLogo} />
                <img src={SailPointLogo} />
              </Cota>
              <Cota>
                <p>Ouro:</p>
                <img src={VitaraLogo} />
                <img src={OfHunterLogo} />
                <img src={AgemLogo} />
                <img src={WithSecureLogo} />
                <img src={LogicalItLogo} />
                <img src={TrendMicroLogo} />
                <img src={ClarotyLogo} />
                <img src={NttLogo} />
                <img src={GigamonLogo} />
              </Cota>
              <Cota>
                <p>Prata:</p>
                <img src={IblissLogo} />
                <img src={ProofPointLogo} />
                <img src={MassimaFrogLogo} />
                <img src={Next4SecLogo} />
                <img src={LumuLogo} />
                <img src={RSecGroupLogo} />
              </Cota>
            </ContentSponsors>
          </Content>
        </>
      )}
    </Wrapper>
  );
};

export default CyberSecurity;
